import React from 'react';
import './UseCases.css'; // Optional: External CSS for custom styling

const useCases = [
  {
    title: 'Brand Monitoring',
    description: 'Marketers and brands can track public sentiment around their products or services by analyzing Reddit discussions. This helps identify positive, negative, or neutral feedback and improve product offerings.',
    icon: '📦', // Emoji as an icon, you can also use an image or a different icon if needed.
  },
  {
    title: 'Market Research',
    description: 'Companies conducting market research can use sentiment analysis to gauge public opinion on various topics. The tool helps collect insights into trends, opinions, and customer preferences from Reddit discussions.',
    icon: '📊',
  },
  {
    title: 'Social Media Listening',
    description: 'Content creators or social media analysts can use sentiment analysis to monitor public opinion on specific topics, events, or trending hashtags, assisting in content strategy and audience engagement.',
    icon: '🎧',
  },
  {
    title: 'Political Campaigns',
    description: 'Political analysts or campaign teams can assess how their messages or opponents are perceived by the public by analyzing Reddit conversations about political topics, allowing them to adjust strategies accordingly.',
    icon: '🏛️',
  },
  {
    title: 'Consumer Sentiment Tracking',
    description: 'Businesses can track sentiment shifts regarding their industry, competitors, or broader market trends. By staying updated on public sentiment, they can make informed business decisions and improve customer satisfaction.',
    icon: '💬',
  },
];

const UseCases = () => {
  return (
    <section className="use-cases-section">
      <h2 className="section-title">Use Cases</h2>
      <div className="use-cases-container">
        {useCases.map((useCase, index) => (
          <div key={index} className="use-case-card">
            <div className="use-case-icon">{useCase.icon}</div>
            <h3 className="use-case-title">{useCase.title}</h3>
            <p className="use-case-description">{useCase.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default UseCases;
