import React from 'react';

const ContactUs = () => {
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '40px 20px',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif',
      lineHeight: '1.6',
      color: '#333'
    },
    header: {
      marginBottom: '40px',
      textAlign: 'center',
      borderBottom: '2px solid #eee',
      paddingBottom: '20px'
    },
    title: {
      fontSize: '32px',
      fontWeight: '700',
      color: '#2c3e50',
      marginBottom: '15px'
    },
    intro: {
      fontSize: '18px',
      color: '#666',
      maxWidth: '600px',
      margin: '0 auto'
    },
    section: {
      marginBottom: '40px',
      padding: '25px',
      backgroundColor: '#f8f9fa',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
    },
    sectionTitle: {
      fontSize: '24px',
      fontWeight: '600',
      color: '#2c3e50',
      marginBottom: '20px'
    },
    contactItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '15px'
    },
    label: {
      fontWeight: '600',
      minWidth: '100px',
      color: '#2c3e50'
    },
    value: {
      color: '#3498db',
      textDecoration: 'none'
    },
    socialLinks: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px'
    },
    socialLink: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: '#3498db',
      padding: '8px 0'
    }
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>Contact Us</h1>
        <p style={styles.intro}>
          We'd love to hear from you! Whether you have questions, feedback, or need assistance,
          please reach out to us.
        </p>
      </header>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>General Inquiries</h2>
        <div style={styles.contactItem}>
          <span style={styles.label}>Email:</span>
          <a href="mailto:nguyenanh1607132@gmail.com" style={styles.value}>
            nguyenanh1607132@gmail.com
          </a>
        </div>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>Support</h2>
        <div style={styles.contactItem}>
          <span style={styles.label}>Email:</span>
          <a href="mailto:nguyenanh1607132@gmail.com" style={styles.value}>
            nguyenanh1607132@gmail.com
          </a>
        </div>
        <div style={styles.contactItem}>
          <span style={styles.label}>Phone:</span>
          <span style={styles.value}>Update soon</span>
        </div>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>Feedback</h2>
        <p style={{marginBottom: '15px'}}>
          We appreciate your feedback to improve RedditSentiments. If you have suggestions
          or comments, feel free to drop us a message:
        </p>
        <div style={styles.contactItem}>
          <span style={styles.label}>Email:</span>
          <a href="mailto:nguyenanh1607132@gmail.com" style={styles.value}>
            nguyenanh1607132@gmail.com
          </a>
        </div>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>Follow Us</h2>
        <p style={{marginBottom: '15px'}}>Stay connected with us on social media:</p>
        <div style={styles.socialLinks}>
          <a href="#twitter" style={styles.socialLink}>
            <span style={styles.label}>Twitter:</span>
            <span style={styles.value}>Update soon</span>
          </a>
          <a
              href="https://www.linkedin.com/in/iamanhnguyen/"
              style={styles.socialLink}
              target="_blank"
              rel="noopener noreferrer"
          >
              <span style={styles.label}>LinkedIn:</span>
              <span style={styles.value}>My LinkedIn</span>
          </a>
          <a href="#facebook" style={styles.socialLink}>
            <span style={styles.label}>Facebook:</span>
            <span style={styles.value}>Update soon</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;