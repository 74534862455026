import React from 'react';

const Footer = () => {
  const footerStyle = {
    width: '100%',
    padding: '1rem',
    backgroundColor: '#f5f5f5',
    marginTop: 'auto',
    textAlign: 'center',
    color: '#666',
    position: 'relative',
    bottom: '0'
  };

  const containerStyle = {
    maxWidth: '1200px',
    margin: '0 auto'
  };

  return (
    <footer style={footerStyle}>
      <div style={containerStyle}>
        © {new Date().getFullYear()} AustralScience. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;