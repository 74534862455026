import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null); // Close if the same question is clicked again
    } else {
      setActiveIndex(index); // Open the clicked question
    }
  };

  const faqData = [
    {
      question: 'What types of keywords can I search for?',
      answer: 'You can search for any keyword that appears in Reddit posts, including product names, topics, or general interests.',
    },
    {
      question: 'How accurate is the sentiment analysis?',
      answer: 'The sentiment analysis is powered by advanced NLP models and has high accuracy, but results can vary based on context.',
    },
    {
      question: 'Can I analyze posts from specific subreddits?',
      answer: 'Yes, you can filter by specific subreddits to target posts related to particular communities or interests.',
    },
    {
      question: 'Is there a limit to how many posts I can analyze?',
      answer: 'Currently, you can analyze up to 1000 posts per search, including most recent posts and popular posts from the past decade.',
    },
  ];

  return (
    <div className="faq-section">
      <h2>Frequently Asked Questions</h2>
      <div className="faq-list">
        {faqData.map((item, index) => (
          <div key={index} className="faq-item">
            <div
              className="faq-question"
              onClick={() => toggleAnswer(index)}
            >
              <span>{item.question}</span>
              <span className={`faq-toggle ${activeIndex === index ? 'open' : ''}`}>+</span>
            </div>
            {activeIndex === index && (
              <div className="faq-answer">{item.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
