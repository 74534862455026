import React from 'react';

const HowToUse = () => {
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '40px 20px',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif',
      lineHeight: '1.6',
      color: '#333'
    },
    header: {
      marginBottom: '30px',
      borderBottom: '2px solid #eee',
      paddingBottom: '20px'
    },
    title: {
      fontSize: '32px',
      fontWeight: '700',
      color: '#2c3e50',
      marginBottom: '20px'
    },
    welcome: {
      fontSize: '18px',
      color: '#666',
      marginBottom: '30px'
    },
    step: {
      marginBottom: '35px'
    },
    stepTitle: {
      fontSize: '22px',
      fontWeight: '600',
      color: '#2c3e50',
      marginBottom: '15px'
    },
    description: {
      marginBottom: '15px'
    },
    list: {
      marginLeft: '25px',
      marginTop: '15px'
    },
    listItem: {
      marginBottom: '10px',
      position: 'relative'
    },
    emphasis: {
      fontWeight: '600',
      color: '#2c3e50'
    },
    note: {
      color: '#666',
      fontStyle: 'italic',
      fontSize: '14px'
    }
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>How to Use</h1>
        <p style={styles.welcome}>
          Welcome to RedditSentiments! This guide will walk you through how to use the app
          to gain insights into public opinion based on Reddit posts.
        </p>
      </header>

      <div style={styles.step}>
        <h2 style={styles.stepTitle}>Step 1: Enter a Keyword</h2>
        <p style={styles.description}>
          In the main search bar, type in the keyword or topic you're interested in
          (e.g., "AI", "Climate Change", or "Brand X"). This will trigger RedditSentiments
          to search for relevant Reddit posts that contain your keyword.
        </p>
      </div>

      <div style={styles.step}>
        <h2 style={styles.stepTitle}>Step 2: Select a Subreddit (Optional)</h2>
        <p style={styles.description}>
          You can narrow your results by selecting a specific subreddit. If you prefer to
          analyze a particular subreddit (e.g., r/technology or r/marketing), simply choose
          it from the dropdown menu.
        </p>
      </div>

      <div style={styles.step}>
        <h2 style={styles.stepTitle}>Step 3: Choose a Time Filter</h2>
        <p style={styles.description}>Pick a time filter to refine the results:</p>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            <span style={styles.emphasis}>All Time:</span> Results from all posts containing the keyword.
          </li>
          <li style={styles.listItem}>
            <span style={styles.emphasis}>Yesterday:</span> Posts from the previous day.
          </li>
          <li style={styles.listItem}>
            <span style={styles.emphasis}>Last Month:</span> Posts from the past 30 days.
          </li>
          <li style={styles.listItem}>
            <span style={styles.emphasis}>Last Year:</span> Posts from the previous year.
          </li>
        </ul>
      </div>

      <div style={styles.step}>
        <h2 style={styles.stepTitle}>Step 4: View Sentiment Analysis</h2>
        <p style={styles.description}>Once you've entered your keyword, RedditSentiments will:</p>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            Analyze posts for sentiment, categorizing them as <span style={styles.emphasis}>Positive</span>,
            <span style={styles.emphasis}> Negative</span>, or <span style={styles.emphasis}>Neutral</span>.
          </li>
          <li style={styles.listItem}>
            Show a <span style={styles.emphasis}>pie chart</span> displaying sentiment distribution.
          </li>
          <li style={styles.listItem}>
            Provide a <span style={styles.emphasis}>word cloud</span> of frequently mentioned words in the posts.
          </li>
        </ul>
      </div>

      <div style={styles.step}>
        <h2 style={styles.stepTitle}>Step 5: Review Data and Insights</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            Explore a detailed <span style={styles.emphasis}>data table</span> with sentiment scores.
          </li>
          <li style={styles.listItem}>
            Download the analysis as a CSV file for further use.
          </li>
        </ul>
      </div>

      <div style={styles.step}>
        <h2 style={styles.stepTitle}>Step 6: Get Insightful Results</h2>
        <p style={styles.description}>
          Based on the analysis, RedditSentiments will also generate an <span style={styles.emphasis}>
          insight report</span>. This report summarizes the general sentiment surrounding your topic,
          providing useful insights for marketers, brands, and researchers.
        </p>
      </div>
    </div>
  );
};

export default HowToUse;