import React from 'react';

const TermsOfUse = () => {
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '40px 20px',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif',
      lineHeight: '1.6',
      color: '#333'
    },
    header: {
      borderBottom: '2px solid #eee',
      paddingBottom: '20px',
      marginBottom: '30px'
    },
    title: {
      fontSize: '32px',
      fontWeight: '700',
      color: '#2c3e50',
      marginBottom: '10px'
    },
    date: {
      color: '#666',
      fontSize: '14px',
      marginBottom: '20px'
    },
    section: {
      marginBottom: '30px'
    },
    sectionTitle: {
      fontSize: '20px',
      fontWeight: '600',
      color: '#2c3e50',
      marginBottom: '15px'
    },
    paragraph: {
      marginBottom: '15px'
    },
    list: {
      marginLeft: '20px',
      marginBottom: '15px'
    },
    listItem: {
      marginBottom: '8px'
    }
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>Terms of Use</h1>
        <div style={styles.date}>Effective Date: November 29, 2024</div>
      </header>

      <div style={styles.section}>
        <p style={styles.paragraph}>
          Welcome to RedditSentiments! By accessing or using this website or the services provided,
          you agree to comply with and be bound by the following terms and conditions.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>1. User Agreement</h2>
        <p style={styles.paragraph}>
          RedditSentiments provides sentiment analysis based on Reddit posts. By using the service,
          you agree to input only lawful and non-infringing keywords and content. We reserve the right
          to suspend or terminate your access to the site if we detect any violations of these terms.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>2. Privacy</h2>
        <p style={styles.paragraph}>
          We value your privacy. RedditSentiments will not share your personal information with third
          parties except as required by law. For more details, please refer to our Privacy Policy.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>3. Usage Rights</h2>
        <p style={styles.paragraph}>
          You are granted a non-exclusive, non-transferable license to use RedditSentiments. The service
          is provided "as is" without warranties of any kind, either express or implied. We do not
          guarantee the accuracy or reliability of the sentiment analysis or any results obtained through
          the use of this site.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>4. Prohibited Uses</h2>
        <p style={styles.paragraph}>You agree not to:</p>
        <ul style={styles.list}>
          <li style={styles.listItem}>Use RedditSentiments for any unlawful, harmful, or abusive activities.</li>
          <li style={styles.listItem}>Interfere with or disrupt the functionality of the site.</li>
          <li style={styles.listItem}>Reverse-engineer or attempt to gain unauthorized access to any part of the service.</li>
        </ul>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>5. Limitation of Liability</h2>
        <p style={styles.paragraph}>
          RedditSentiments is not liable for any direct, indirect, incidental, special, or consequential
          damages that may arise from your use or inability to use the service.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>6. Modifications</h2>
        <p style={styles.paragraph}>
          We may modify these terms at any time. We will post updates on this page, and your continued
          use of the site after such modifications constitutes your acceptance of the new terms.
        </p>
      </div>
    </div>
  );
};

export default TermsOfUse;